import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import { Modal, Input, Table, Button, Space, Tooltip, Select } from 'antd';
import { providerlist, addprovider, updatestatus, updateblock, updateprovider } from './../../Store/Action/Master/Serviceprovider';
import moment from 'moment';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import {
    LikeOutlined, DislikeOutlined,
    PlusOutlined, InfoCircleOutlined, FileAddOutlined, SearchOutlined, PlusCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons';



const mapStateToProps = (state) => ({
    lists: state.Serviceprovider.list.service_providers,
    filterrecall: state.recallfilters.recallfilters
})
const Serviceproviderlist = ({
    providerlist,
    lists,
    addprovider,
    updatestatus,
    updateblock,
    updateprovider,
    GetRemarksData,
    filterrecall: { recallremarks },
}) => {
    const { TextArea } = Input;
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const location = useLocation();
    const paramid = location.pathname;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [modalVisible, SetmodalVisible] = useState(false);
    const [passvisible, Setpassvisible] = useState(false);
    const [blockvisible, Setblockvisible] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [remarksvalue, Setremarksvalue] = useState();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        background: '#DADADA'
    })
    const { Search } = Input;
    const [remarksModal, setRemarksModal] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [page, setPage] = useState(1);

    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        active_status: ''
    })

    const [blockdata, Setblockdata] = useState({
        "sp_id": '',
        "is_blocked": '',
        "remarks": '',
        "password": ''
    })

    const modalcancel = () => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: '',
            contact_no1: '',
            contact_no2: '',
            contact_no: '',
            short_name: '',
            mobile_no: '',
            mobile_no1: '',
            Contract_from: '',
            Contract_to: '',
            contact_person: '',
            email: '',
            address: '',
        })

        SetmodalVisible(false);
    }

    const addopen = () => {
        SetmodalVisible(true);
    }

    const passwordcancel = () => {
        Setpassvisible(false);
    }

    const blockcancel = () => {
        Setblockvisible(false);
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const handleChange = (pagination, filters, sorter) => {
        console.log('pageeee');
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    var myColTitleStyle = {
        textOverflow: 'ellipsis',
        // overflow: "hidden",
        whiteSpace: 'nowrap'
    };
    const columns = [
        {
            title: 'Service Provider',
            dataIndex: 's_p_name',
            key: 's_p_name',
            ...getColumnSearchProps('s_p_name'),
            ellipsis: true,
            width: '150px'
        },
        {
            title: 'Contact Person',
            dataIndex: 'cont_person',
            key: 'cont_person',
            // ...getColumnSearchProps('cont_person'),
            ellipsis: true,
            width: '150px'
        },
        {
            title: 'Contact No',
            dataIndex: 'cont_no',
            key: 'cont_no',
            // ...getColumnSearchProps('cont_no'),
            ellipsis: true,
            width: '150px'
        },
        {
            title: 'Email ID',
            dataIndex: 'e_id',
            key: 'e_id',
            // ...getColumnSearchProps('e_id'),
            ellipsis: true,
            width: '150px'
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            // ...getColumnSearchProps('remarks'),
            ellipsis: true,
            width: '150px'
        },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Action Includes Edit, Active/Inactive Status Change, Block/Unblock Status Change"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_blocked',
            width: '150px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div className='row'>
                            <div className='col-md-3'>
                                {text == false ?
                                    <div className='col-md-2'>
                                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                            onClick={(e) => { editservice(record) }}>
                                            <Tooltip title="Edit Service Provider" color='#39cb7f'>
                                                <i className="ri-edit-line"></i></Tooltip>
                                        </span>
                                    </div>
                                    :
                                    <div className='col-md-2'>
                                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"

                                        ><Tooltip title="Edit Service Provider" color='#39cb7f'>
                                                <i className="ri-edit-line" style={{ cursor: 'not-allowed' }}></i></Tooltip>
                                        </span>
                                    </div>
                                }
                            </div>

                            <div className='col-md-3'>
                                {record.is_blocked == false ?
                                    <div>
                                        {record.is_active === true ?
                                            <span className="btn btn-sm mb-1 badge
font-weight-medium bg-light-success text-success w-80"  onClick={(e) => { statuschange(record) }}><Tooltip title="Active" color='#39cb7f'><LikeOutlined /></Tooltip></span>
                                            :
                                            <span className="btn btn-sm mb-1
badge font-weight-medium bg-light-danger text-danger w-80" onClick={(e) => { statuschange(record) }}><Tooltip title="Inactive" color='#fc4b6c'><DislikeOutlined /></Tooltip></span>}
                                    </div>
                                    :
                                    <div>
                                        {
                                            record.is_active === true ?
                                                <span className="btn btn-sm mb-1 badge
font-weight-medium bg-light-success text-success w-80" style={{ cursor: 'not-allowed' }}
                                                // onClick={(e) => { statuschange(row) }}
                                                ><Tooltip title="Active" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="btn btn-sm mb-1
badge font-weight-medium bg-light-danger text-danger w-80" style={{ cursor: 'not-allowed' }}
                                                //  onClick={(e) => { statuschange(row) }}
                                                ><Tooltip title="Inactive" color='#fc4b6c'><DislikeOutlined /></Tooltip></span>
                                        }
                                    </div>
                                }
                            </div>
                            <div className='col-md-3' style={{ paddingLeft: '25px' }}>
                                {text === true ?
                                    <div className='col-md-2'>
                                        <span
                                            className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { blockprovider(record) }}>
                                            <Tooltip title="Block" color='#fc4b6c'><i className="ri-forbid-line"></i></Tooltip>
                                        </span>
                                    </div> :
                                    <div className='col-md-2'>
                                        <span
                                            className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { blockprovider(record) }}>
                                            <Tooltip title="Unblock" color='#39cb7f'><i className="ri-check-line"></i></Tooltip>
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )

            }
        },
    ];

    const [serviceProviderDetails, setServiceProviderDetails] = useState({
        provider_name: '',
        contact_no1: '',
        contact_no2: '',
        contact_no: '',
        short_name: '',
        mobile_no: '',
        mobile_no1: '',
        Contract_from: '',
        Contract_to: '',
        contact_person: '',
        email: '',
        address: '',
    });

    const [sid, Setsid] = useState({
        s_id: ''
    })


    const serviceProviderOnChange = (e) => {
        setServiceProviderDetails({
            ...serviceProviderDetails,
            [e.target.name]: e.target.value
        })
    }



    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetServiceProviders",
                        {}
                    ]
                }
            ]

            providerlist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()


    }, []);

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    const addservice = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            if (sid.s_id === '') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveServiceProvider",
                            {
                                "s_p_name": serviceProviderDetails.provider_name,
                                "s_p_short": serviceProviderDetails.short_name,
                                "cont_from": moment(serviceProviderDetails.Contract_from).format('D-MMM-YYYY'),
                                "cont_to": moment(serviceProviderDetails.Contract_to).format('D-MMM-YYYY'),
                                "cont_person": serviceProviderDetails.contact_person,
                                "cont_no": serviceProviderDetails.contact_no1 + '-' + serviceProviderDetails.contact_no2 + '-' + serviceProviderDetails.contact_no,
                                "mob_no": serviceProviderDetails.mobile_no1 + '-' + serviceProviderDetails.mobile_no,
                                "e_id": serviceProviderDetails.email,
                                "address": serviceProviderDetails.address
                            }
                        ]
                    }
                ];
                addprovider({
                    payload: payload,
                    paramid: paramid
                })
            }
            else {

                const pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateServiceProvider",
                            {
                                "s_p_id": sid.s_id,
                                "s_p_name": serviceProviderDetails.provider_name,
                                "s_p_short": serviceProviderDetails.short_name,
                                "address": serviceProviderDetails.address,
                                "cont_from": moment(serviceProviderDetails.Contract_from).format('D-MMM-YYYY'),
                                "cont_to": moment(serviceProviderDetails.Contract_to).format('D-MMM-YYYY'),
                                "cont_person": serviceProviderDetails.contact_person,
                                "cont_no": serviceProviderDetails.contact_no1 + '-' + serviceProviderDetails.contact_no2 + '-' + serviceProviderDetails.contact_no,
                                "mob_no": serviceProviderDetails.mobile_no1 + '-' + serviceProviderDetails.mobile_no,
                                "e_id": serviceProviderDetails.email
                            }
                        ]
                    }
                ]
                updateprovider({
                    payload: pay,
                    paramid: paramid
                })
            }
        }
    }

    const statuschange = (e) => {
        Setpassvisible(true);
        Setpassword({
            ...passwordata,
            sp_id: e.s_p_id,
            active_status: !e.is_active
        })
    }

    const blockprovider = (e) => {
        Setblockvisible(true);
        Setblockdata({
            ...blockdata,
            sp_id: e.s_p_id,
            is_blocked: !e.is_blocked
        })
    }

    const submitpassword = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ChangeServiceProviderStatus",
                        {
                            "sp_id": passwordata.sp_id,
                            "active_status": passwordata.active_status,
                            "password": passwordata.passvalue
                        }
                    ]
                }
            ]
            updatestatus({
                payload: pay,
                paramid: paramid
            })
        }
    }

    const blockservice = () => {
        setAddFormSubmit2(true)
        if (formValidator2.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "BlockServiceProvider",
                        {
                            "sp_id": blockdata.sp_id,
                            "is_blocked": blockdata.is_blocked,
                            "remarks": blockdata.remarks,
                            "password": blockdata.password
                        }
                    ]
                }
            ]
            updateblock({
                payload: pay,
                paramid: paramid
            })
            Setblockdata({
                ...blockdata,
                password: '',
                remarks: ''
            })
        }
    }

    const editservice = (data) => {
        const phn = data.cont_no.split('-');
        const mbl = data.mob_no.split('-');
        setServiceProviderDetails({
            ...serviceProviderDetails,
            provider_name: data.s_p_name,
            contact_no1: phn[0],
            contact_no2: phn[1],
            contact_no: phn[2],
            short_name: data.s_p_short,
            mobile_no: mbl[1],
            mobile_no1: mbl[0],
            Contract_from: moment(data.cont_from).format('YYYY-MM-DD'),
            Contract_to: moment(data.cont_to).format('YYYY-MM-DD'),
            contact_person: data.cont_person,
            email: data.e_id,
            address: data.address
        })
        Setsid({
            ...sid,
            s_id: data.s_p_id
        })
        SetmodalVisible(true);
    }

    const handleOk = () => {
        setRemarksModal(false)
    };

    const handleCancel = () => {
        setRemarksModal(false)
    };

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '10px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            render: (text, record) => <a onClick={() => {
                Setblockdata({
                    ...blockdata,
                    remarks: record.r_description
                })
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const [baseData, setBaseData] = useState([]);
    // const [commonSearch, setCommonSearch] = useState();
    useEffect(() => {
        if (lists && lists.length > 0) {
            setBaseData(lists);
        }
    }, [lists])


    const searchRecords = (e) => {
        console.log(e.target.value, 'esttes')
        const filterTable = lists.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setBaseData(filterTable)
    };


    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Master</Link>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <Link>Service Provider</Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">

                                <div class="search-box">
                                    <input class="search-txt" type="text" onChange={searchRecords} placeholder="Type to Search" />
                                    <a class="search-btn">
                                        <i class="fas fa-search"></i>
                                    </a>
                                </div>
                                <div>
                                    <Button type="primary" shape="round" className='addbutton add-btn'
                                        icon={<PlusOutlined />} size='default' onClick={addopen} x>
                                        Add
                                    </Button></div>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row" >
                            <div className="col-12">
                                <div className="card">
                                    <div className='card-body greyclr' style={{ padding: '0px' }}>
                                        <Table
                                            className='userprivclass greyclrtbl'
                                            columns={columns}
                                            //dataSource={lists}
                                            dataSource={baseData}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger,
                                                background: dataTableProperties.background
                                            }}
                                            onChange={handleChange} />
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>
            <Modal title="Service Provider" visible={modalVisible} footer={null} maskClosable={false} className="serviceProvider"
                onCancel={modalcancel}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Service Provider</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <Input
                                        type="text"
                                        id="provider_name"
                                        name='provider_name'
                                        placeholder="Enter Service Provider Name"
                                        autoComplete={'off'}
                                        onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.provider_name}
                                    />
                                    {formValidator.current.message(
                                        'provider_name',
                                        serviceProviderDetails.provider_name,
                                        ['required', { regex: /^[A-Za-z ]+$/ }],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter valid name'
                                            }
                                        })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Short Name</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <Input
                                        type="text"
                                        name='short_name'
                                        id="short_name"
                                        className="form-control"
                                        placeholder="Enter Short Name"
                                        autoComplete={'off'}
                                        onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.short_name}
                                    />
                                    {formValidator.current.message(
                                        'short_name',
                                        serviceProviderDetails.short_name,
                                        ['required', { regex: /^[A-Za-z ]+$/ }],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter valid name'
                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contact Number</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <div className="row">
                                        <div className="col-3 pe-0">
                                            <Input
                                                type="number"
                                                name='contact_no1'
                                                placeholder="+00"
                                                autoComplete={'off'}
                                                onChange={serviceProviderOnChange}
                                                value={serviceProviderDetails.contact_no1}
                                            /> {formValidator.current.message(
                                                'contact_no1',
                                                serviceProviderDetails.contact_no1,
                                                [{ regex: /^\+?[0-9]{2}$/ }],

                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        // required: 'Required',
                                                        regex: 'Valid Country Code Required'
                                                    }
                                                })}
                                        </div>
                                        <div className="col-3 pe-0">
                                            <Input
                                                type="number"
                                                name='contact_no2'
                                                placeholder="000"
                                                autoComplete={'off'}
                                                onChange={serviceProviderOnChange}
                                                value={serviceProviderDetails.contact_no2}
                                            />
                                            {formValidator.current.message(
                                                'contact_no2',
                                                serviceProviderDetails.contact_no2,
                                                [{ regex: /^(\+\d{1,3}[- ]?)?\d{3,4}$/ }],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        // required: 'Required Field Missing',
                                                        regex: 'Enter valid input'
                                                    }
                                                })}
                                        </div>
                                        <div className="col-6">
                                            <Input
                                                type="number"
                                                id="contact_no"
                                                name='contact_no'
                                                placeholder="1234567890"
                                                onChange={serviceProviderOnChange}
                                                value={serviceProviderDetails.contact_no}
                                            />
                                            {formValidator.current.message(
                                                'contact_no',
                                                serviceProviderDetails.contact_no,
                                                ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                        regex: 'Enter valid mobile number'
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Mobile Number</b></label>

                                    <div className="row">
                                        <div className="col-3 pe-0">
                                            <Input
                                                type="number"
                                                name='mobile_no1'
                                                autoComplete={'off'}
                                                placeholder="+00"
                                                onChange={serviceProviderOnChange}
                                                value={serviceProviderDetails.mobile_no1}
                                            />

                                            {formValidator.current.message(
                                                'mobile_no1',
                                                serviceProviderDetails.mobile_no1,
                                                [{ regex: /^\+?[0-9]{2}$/ }],

                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        // required: 'Required',
                                                        regex: 'Valid Country Code Required'
                                                    }
                                                })}

                                        </div>
                                        <div className="col-9">
                                            <Input
                                                type="number"
                                                id="contact_no"
                                                name='mobile_no'
                                                autoComplete={'off'}
                                                placeholder="0000000000"
                                                onChange={serviceProviderOnChange}
                                                value={serviceProviderDetails.mobile_no}
                                            />
                                            {/* {formValidator.current.message(
                                                'mobile_no',
                                                serviceProviderDetails.mobile_no,
                                                [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {

                                                        regex: 'Enter valid mobile number'
                                                    }
                                                    
                                                })} */}

                                            {formValidator.current.message(
                                                'mobile_no1',
                                                serviceProviderDetails.mobile_no,
                                                [
                                                    'required',
                                                    {
                                                        regex: /^[0-9]+$/,
                                                    },
                                                    `min:10`,
                                                    `max:10`
                                                ],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter  Mobile Number',
                                                        regex: 'Enter Numbers Only',
                                                        min: 'mobile number should be 10 digits',
                                                        max: 'mobile number should be 10 digits'
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contract From</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <Input
                                        type="date"
                                        id="Contract_from"
                                        name='Contract_from'
                                        autoComplete={'off'}
                                        placeholder="Enter Email address"
                                        onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.Contract_from}
                                    />
                                    {formValidator.current.message(
                                        'Contract_from',
                                        serviceProviderDetails.Contract_from,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter valid email'
                                            }
                                        })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contract To</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <Input
                                        type="date"
                                        id="Contract_to"
                                        name='Contract_to'
                                        autoComplete={'off'}
                                        placeholder="User ID"
                                        onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.Contract_to}
                                        min={serviceProviderDetails.Contract_from}
                                    />
                                    {formValidator.current.message(
                                        'Contract_to',
                                        serviceProviderDetails.Contract_to,
                                        ['required'],


                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter valid email',

                                            }
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Email Address</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <Input
                                        type="text"
                                        id="email"
                                        name='email'
                                        autoComplete={'off'}
                                        placeholder="Enter Email Address"
                                        onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.email}
                                    />
                                    {formValidator.current.message(
                                        'email',
                                        serviceProviderDetails.email,
                                        ['required', { regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter valid email'
                                            }
                                        })}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Contact Person</b></label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <Input
                                        type="text"
                                        id="contact_person"
                                        name='contact_person'
                                        autoComplete={'off'}
                                        placeholder="Enter Contact Person"
                                        onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.contact_person}
                                    />
                                    {formValidator.current.message(
                                        'contact_person',
                                        serviceProviderDetails.contact_person,
                                        ['required', { regex: /^[A-Za-z ]+$/ }],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter valid name'
                                            }
                                        })}

                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2">
                                    <label><b>Address</b></label>
                                    <TextArea className="form-control" rows={4} id="address"
                                        name='address' onChange={serviceProviderOnChange}
                                        value={serviceProviderDetails.address}></TextArea>
                                </div>
                            </div>
                        </div>

                        <div className="form-actions">

                            <button
                                className="btn btn-light-success rounded-pill px-4 text-success"
                                onClick={addservice}> Save

                            </button>
                            <button
                                className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger" onClick={modalcancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal visible={passvisible} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label>Enter Password</label>
                            <span style={{ "color": "red" }}>*</span>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                                value={passwordata.passvalue}
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        regex: 'Enter Password'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <button
                        className="btn btn-light-success rounded-pill px-4 text-success"
                        onClick={submitpassword}
                    >Submit
                    </button>
                    <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                        onClick={passwordcancel}
                    >Cancel
                    </button>
                </div>
            </Modal>
            <Modal visible={blockvisible} footer={null}
                onCancel={blockcancel}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pt-3">
                            <div className="col-md-6">
                                <div className='mb-2'>
                                    <label className="control-label">Password:</label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <input
                                        type="password"
                                        id="password"
                                        name='passwordvalue'
                                        className="form-control"
                                        placeholder="Enter password"
                                        onChange={(e) => {
                                            Setblockdata({
                                                ...blockdata,
                                                password: e.target.value
                                            })
                                        }}
                                        value={blockdata.password}
                                    />
                                    {formValidator2.current.message(
                                        'Password',
                                        blockdata.password,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter Password'
                                            }
                                        })}
                                </div>

                            </div>
                            <div className='col-md-5'>
                                <div className="mb-2">
                                    <label className="control-label">Reason:</label>
                                    <span style={{ "color": "red" }}>*</span>

                                    <textarea className='form-control' name='remark' value={blockdata.remarks}
                                        row='1' cols='3' style={{ height: '41px' }} onChange={(e) => {
                                            Setblockdata({
                                                ...blockdata,
                                                remarks: e.target.value
                                            })

                                        }}>
                                    </textarea>
                                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" style={{ "float" : "right"}}
                                        onClick={(e) => { showRemarksModal() }}>
                                        <PlusOutlined />
                                    </span>
                                    {formValidator2.current.message(
                                        'remark',
                                        blockdata.remarks,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                            messages: {
                                                regex: 'Enter Remarks'
                                            }
                                        })}

                                </div>
                                {/* <div className="col-md-1" style={{ float: 'right' }}>
                                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                        onClick={(e) => { showRemarksModal() }}>
                                        <PlusOutlined /> */}
                                {/* </span> */}
                                {/* <PlusOutlined onClick={() => { showRemarksModal() }} style={{ marginLeft: '100%', fontSize: '25px' }}></PlusOutlined> */}
                                {/* <i class="ri-add-box-fill" onClick={() => { showRemarksModal() }} style={{ marginLeft: '650%' }}></i> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="form-actions">
                    <button
                        className="btn btn-light-success rounded-pill px-4 text-success"
                        onClick={blockservice}
                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger
               waves-effect text-start"
                        onClick={blockcancel}
                    > Cancel

                    </button>
                </div>
            </Modal>
            <Modal title="Select Remarks" footer={null} visible={remarksModal} className="remarksClass"
                onOk={handleOk}
                onCancel={handleCancel}>
                {/* <DataTable
                    columns={RemarksColumn}
                    data={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    pagination
                    responsive

                /> */}
                <Table
                    className='userprivclass'
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    pagination={{
                        defaultPageSize: dataTableProperties.pagesize,
                        showSizeChanger: dataTableProperties.sizechanger
                    }}
                />

            </Modal>
        </Fragment>
    )
}

export default connect(mapStateToProps, {
    providerlist,
    addprovider,
    updatestatus,
    updateblock,
    updateprovider,
    GetRemarksData
})(Serviceproviderlist);